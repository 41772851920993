import React from 'react';

import './styles.scss';

const LegalHero: React.FC = ({children}) => (
  <section className="legal-hero">
  <div className="container">
    <div className="row">
      <div className="col-12 col-lg-8 offset-lg-2">
        {children}
      </div>
    </div>
  </div>
</section>
)

export default LegalHero;
