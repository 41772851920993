import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { graphql } from 'gatsby';
import LocalizedLink from '../components/LocalizedLink/LocalizedLink';
import IndexLayout from '../layouts';

import SEO from '../components/SEO/SEO';

import Footer from '../components/Footer/Footer';
import LegalHero from '../components/Legal/LegalHero/LegalHero';
import { LocaleTypes } from '../data/locales';

interface LegalTemplateProps {
  data: {
    contentfulLegalDocument: {
      title: string;
      slug: string;
      country: {
        code: string;
        name: string;
        countryBall: {
          localFile: {
            publicURL: string;
          }
        };
      };
      content: {
        childMarkdownRemark: {
          html: string;
        }
      };
      file: {
        localFile: {
          publicURL: string;
        }
      };
      updatedAt: string;
    };
  };
  pathContext: {
    locale: LocaleTypes;
  },
  location: Location;
}


const LegalTemplate: React.SFC<LegalTemplateProps> = ({ data, pathContext, location }) => {
  const content = ( ): React.ReactNode => {
    const { contentfulLegalDocument } = data;
    const html = contentfulLegalDocument.content ? contentfulLegalDocument.content.childMarkdownRemark.html : undefined;
    const file = contentfulLegalDocument.file ? contentfulLegalDocument.file.localFile.publicURL : undefined;

    return (
      <>
        <SEO
          title={contentfulLegalDocument.title}
        />
        <LegalHero>

          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><LocalizedLink to="/legal"><FormattedMessage id="legal.title"/></LocalizedLink></li>
              <li className="breadcrumb-item active" aria-current="page">{contentfulLegalDocument?.country?.name} - {contentfulLegalDocument.title}</li>
            </ol>
          </nav>

          <div className="country-display">
            <img src={contentfulLegalDocument.country?.countryBall.localFile.publicURL} alt={contentfulLegalDocument?.country?.name}/>
            {contentfulLegalDocument?.country?.name}
          </div>
          <h1>{contentfulLegalDocument.title}</h1>

          <p className="lead"><FormattedMessage id="legal.document.last_update" />: {contentfulLegalDocument.updatedAt}</p>
        </LegalHero>
        <section className="pt-5 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8 offset-lg-2">
                {
                  file &&
                  <a href={file} target="_blank" className="lead mb-layout-3 d-block" rel="noopener">
                    <FormattedMessage id="legal.document.download_pdf" />
                    <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-2">
                      <path d="M21.2784 15.2948V19.2948C21.2784 19.8252 21.0677 20.3339 20.6926 20.709C20.3175 21.0841 19.8088 21.2948 19.2784 21.2948H5.27838C4.74795 21.2948 4.23924 21.0841 3.86417 20.709C3.48909 20.3339 3.27838 19.8252 3.27838 19.2948V15.2948" stroke="#023365" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M7.27838 10.2948L12.2784 15.2948L17.2784 10.2948" stroke="#023365" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12.2784 15.2948V3.2948" stroke="#023365" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    </a>
                }
                {
                  html &&
                  <div
                  className="markdown-content"
                  dangerouslySetInnerHTML={{ __html: html }}
                  />
                }
              </div>
            </div>
          </div>
        </section>
        <Footer theme="primary" />
      </>
    )
  }

  return (
    <IndexLayout
      render={ content }
      locale= { pathContext.locale }
      location={location}
    />
  );
};

export default LegalTemplate;

export const query = graphql`
  query($slug: String!, $locale: String!) {
    contentfulLegalDocument(slug: {eq: $slug}, node_locale: {eq: $locale}) {
      title
      slug
      country {
        code
        name
        countryBall {
          localFile {
            publicURL
          }
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      file {
        localFile {
          publicURL
        }
      }
      updatedAt(formatString: "MMMM DD, YYYY", locale: $locale)
    }
  }
`;
